import type { NextPage } from 'next';
import { useEffect, useState, ChangeEvent } from 'react';
import Image from 'next/image';
import {
  Box, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, Typography, TextField, Button, CircularProgress, Alert
} from '@mui/material';
import classes from './selectOrganization.module.scss';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { Organization } from '../../types/Clerk.interfaces';
import { useOrganization, useOrganizationList, useUser, useClerk } from '@clerk/nextjs';
import axios, { AxiosResponse } from 'axios';
import { OrganizationResource } from '@clerk/types';
import logo from '../../public/images/logo.png';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

declare global {
  namespace React.JSX {
    // eslint-disable-next-line no-unused-vars
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const SelectOrganization: NextPage = () => {
  const { organizations, subscriptionCanceled, setSubscriptionCanceled, clientAdmin } = useGlobalState();
  const { openOnboardingModal, setOpenOnboardingModal } = useUiState();
  const { setActive } = useOrganizationList();
  const { organization } = useOrganization();
  const { user } = useUser();
  const { signOut } = useClerk();
  const [value] = useState<string>('');
  const [orgName, setOrgName] = useState<string>('');
  const [step, setStep] = useState<string>('createOrg');
  const [creatingOrganization, setCreatingOrganization] = useState<boolean>(false);
  const [creatingOrganizationError, setCreatingOrganizationError] = useState<boolean>(false);
  const [controller] = useState<AbortController>(new AbortController());

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (openOnboardingModal) {
      if (organization) {
        setStep('selectProduct');
      } else {
        setStep('createOrg');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOnboardingModal]);

  const handleSelectOrganization = (event: SelectChangeEvent<string>) => {
    const org = organizations.find((client: Organization) => client.id === event.target.value);
    if (org && setActive) {
      setOpenOnboardingModal(false);
      setActive({ organization: org.organization.id });
    }
  };

  const handleCreateOrganization = async () => {
    try {
      setCreatingOrganization(true);
      setCreatingOrganizationError(false);
      const response: AxiosResponse<{ message: string; organization: OrganizationResource }> = await axios.post(
        '/api/clients',
        { name: orgName, user: user },
        { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
      );
      if (response.data.organization) {
        setActive && setActive({ organization: response.data.organization.id });
      }
      setStep('selectProduct');
    } catch (error) {
      if (!axios.isCancel(error)) {
        setCreatingOrganizationError(true);
      }
    } finally {
      setCreatingOrganization(false);
    }
  };

  const returnStep = (): React.ReactElement => {
    if (step === 'createOrg') {
      return (
        <Box className='u-flex u-flex-align-center u-flex-direction-column'>
          <Typography
            component='p'
            className={`${classes.paragraph} u-pb-12`}
          >
            Create an organization
          </Typography>
          <Box sx={{ minWidth: '500px' }} className='u-text-align-center'>
            <TextField
              value={orgName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setOrgName(event.target.value)}
              className='search u-w-100'
              placeholder='Organization Name'
            />
          </Box>
          <Box className='u-pt-20 u-flex' sx={{ gap: '20px' }}>
            <Button
              className='details-button u-w-100-px'
              variant='outlined'
              onClick={() => signOut()}
            >
              Cancel
            </Button>
            <Button
              className='contained-crm-button u-w-100'
              color='primary'
              variant='contained'
              classes={{
                disabled: 'disabled-button'
              }}
              disabled={!orgName || creatingOrganization}
              onClick={() => handleCreateOrganization()}
            >
              {creatingOrganization ? <CircularProgress sx={{ color: '#fff', marginRight: '5px' }} size={20} /> : null}
              Continue
            </Button>
          </Box>
          {creatingOrganizationError ? (
            <Box className='u-my-32'>
              <Alert
                severity='error'
                variant='filled'
                icon={<WarningAmberOutlinedIcon sx={{ color: '#fff' }} className='u-mt-4' />}
                sx={{ background: '#BA1A1A', alignItems: 'flex-start !important', maxWidth: '600px' }}
              >
                <Typography className='fs-body-regular u-fw-500' sx={{ color: '#fff' }}>Onboarding error</Typography>
                <Typography className='fs-body-regular u-fw-400' sx={{ color: '#fff' }}>
                  There was an internal error while creating your account. Please wait a few moments and
                  {' '}
                  try again. If this error persists please contact Customer Support.
                </Typography>
              </Alert>
            </Box>
          ) : null}
        </Box>
      );
    } else if (step === 'selectProduct') {
      return (
        <Box>
          <Box className='u-px-24 u-py-72'>
            <stripe-pricing-table
              pricing-table-id={process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID as string}
              publishable-key={process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string}
              customer-email={user ? user.primaryEmailAddress?.emailAddress : undefined}
            >
            </stripe-pricing-table>
            <Box className='u-flex u-flex-justify-center u-pb-12'>
              <Button
                className='details-button u-w-100-px'
                variant='outlined'
                onClick={() => signOut()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box className={classes.onboardingWrapper}>
      <Box className={classes.modal}>
        <Box className={classes.imageWrapper}>
          <Image
            src={logo}
            alt='Revenue Base Logo'
            width={200}
          />
        </Box>
        {!organization && organizations.length > 0 ? (
          <Box>
            <Box>
              <Box component='p' className={classes.paragraph}>Select organization to enter:</Box>
            </Box>
            <Box className='u-flex u-flex-justify-center u-w-100'>
              <FormControl variant='standard' className='full-width' sx={{ minWidth: '200px', paddingBottom: '20px' }}>
                <InputLabel id='organization-label'>Organization</InputLabel>
                <Select
                  labelId='organization-label'
                  id='organization'
                  value={value}
                  onChange={(event: SelectChangeEvent<string>) => handleSelectOrganization(event)}
                  sx={{ textAlign: 'left' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    classes: {
                      paper: 'paper'
                    }
                  }}
                >
                  {organizations.map((org: Organization) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.organization.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        ) : (
          <Box className='u-w-100'>
            {subscriptionCanceled ? (
              clientAdmin ? (
                <>
                  <Typography
                    component='p'
                    className={`${classes.paragraph} u-pb-12`}
                  >
                    Welcome back
                  </Typography>
                  <Typography className='u-text-align-center'>Your subscription has expired to {organization?.name}</Typography>
                  <Box className='u-flex u-flex-justify-center u-py-24'>
                    <Button
                      className='contained-crm-button u-mx-4'
                      color='primary'
                      variant='contained'
                      classes={{
                        disabled: 'disabled-button'
                      }}
                      onClick={() => {
                        setStep('selectProduct');
                        setSubscriptionCanceled(false);
                      }}
                    >
                      Reactivate your account
                    </Button>
                    <Button
                      className='contained-crm-button u-mx-4'
                      color='primary'
                      variant='contained'
                      classes={{
                        disabled: 'disabled-button'
                      }}
                      onClick={() => {
                        setStep('createOrg');
                        setSubscriptionCanceled(false);
                      }}
                    >
                      Create new organization
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    component='p'
                    className={`${classes.paragraph} u-pb-12`}
                  >
                    Welcome back
                  </Typography>
                  <Typography className='u-text-align-center u-pb-12'>Your subscription has expired to {organization?.name}</Typography>
                  <Typography className='u-text-align-center u-pb-12'>To reactivate this account contact your administrator.</Typography>
                  <Typography className='u-text-align-center'>Or</Typography>
                  <Box className='u-flex u-flex-justify-center u-py-24'>
                    <Button
                      className='contained-crm-button u-mx-4'
                      color='primary'
                      variant='contained'
                      classes={{
                        disabled: 'disabled-button'
                      }}
                      onClick={() => {
                        setStep('createOrg');
                        setSubscriptionCanceled(false);
                      }}
                    >
                      Create new organization
                    </Button>
                  </Box>
                </>
              )
            ) : (
              <>{returnStep()}</>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SelectOrganization;
