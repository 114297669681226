import type { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useGlobalState } from '../../context/globalState';
import { User } from '../../types/Common.interfaces';
import { Box, Checkbox, FormControlLabel, IconButton, Typography, Popover, Skeleton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  anchorEl: null | HTMLElement;
  handleClose: (event:  MouseEvent | TouchEvent | React.MouseEvent<HTMLElement>) => void;
  handleFilterChange: (value: string[]) => void;
  title: string;
  selectedOptions: string[];
  filterEntity: string;
}

const TableCheckboxFilter: NextPage<Props> = ({
  anchorEl, handleClose, handleFilterChange, title, selectedOptions, filterEntity
}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [controller] = useState<AbortController>(new AbortController());
  const { organizationData } = useGlobalState();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (organizationData && anchorEl && !users.length) {
      handleGetUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, anchorEl]);

  const handleGetUsers = async () => {
    if (organizationData) {
      try {
        setLoadingUsers(true);
        const response: AxiosResponse<User[]> = await axios.get(
          `/api/clients/${organizationData.id}/users?entity=${filterEntity}`,
          { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
        );
        setUsers(response.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setUsers([]);
        }
      } finally {
        setLoadingUsers(false);
      }
    }
  };

  const filterChanged = (userId: string, checked: boolean) => {
    const selectedUserIds = checked
      ? [...selectedOptions, userId]
      : selectedOptions.filter((item: string) => item !== userId);

    handleFilterChange(selectedUserIds);
  };

  const checkValue = (userId: string): boolean => {
    return selectedOptions.includes(userId);
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Box className='table-menu checkbox-multi-select'>
        <Box className='title-container u-sticky u-w-100 u-px-16 u-py-12' sx={{ top: 0 }}>
          <Box className='u-flex u-flex-space-between u-flex-align-center'>
            <Typography className='u-m-0'>Filters</Typography>
            <IconButton
              sx={{ height: 'fit-content' }}
              onClick={(event: React.MouseEvent<HTMLElement>) => handleClose(event)}
            >
              <CloseRoundedIcon sx={{ color: '#2f3b4a', fontSize: '16px' }} />
            </IconButton>
          </Box>
          <Typography className='u-mt-24 u-mb-16'>{title}</Typography>
        </Box>
        <Box className='u-w-100 u-px-16 u-py-12' sx={{ minWidth: '160px' }}>
          {loadingUsers ? (
            Array.from(Array(5).keys()).map((value: number, index: number) => (
              <Skeleton
                key={index + 1}
                variant='rectangular'
                height={30}
                className='u-mt-12'
                sx={{ bgcolor: '#F5EEFA', zIndex: '1' }}
              />
            ))
          ) : (
            users.map((user: User) => (
              <Box key={user.id}>
                <FormControlLabel
                  className='checkbox-button'
                  checked={checkValue(user.id)}
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        filterChanged(event.target.value, event.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      classes={{
                        root: 'checkbox-root'
                      }}
                    />
                  }
                  label={user.firstName ? `${user.firstName} ${user.lastName || ''}` : user.email}
                  value={user.id}
                />
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default TableCheckboxFilter;
