import type { NextPage } from 'next';
import { Box, Tooltip, IconButton } from '@mui/material';
import CloseIconRounded from '@mui/icons-material/CloseRounded';

interface Props {
  handleCloseModal: () => void;
}

const CloseButton: NextPage<Props> = ({ handleCloseModal }) => {
  return (
    <Box component='span' className='close-button' onClick={() => handleCloseModal()}>
      <Tooltip title='Close' placement='bottom' classes={{ tooltip: 'custom-tooltip' }}>
        <IconButton className='u-cursor-pointer'>
          <CloseIconRounded fontSize='small' sx={{ color: '#2f3b4a'}} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CloseButton;
