import type { NextPage } from 'next';
import { useState, useEffect } from 'react';
import { Dialog, Box, Typography, Skeleton } from '@mui/material';
import useWindowSize from '../../utils/useWindowSize';
import { useGlobalState } from '../../context/globalState';
import axios, { AxiosResponse } from 'axios';
import { Product } from '../../types/Stripe.interfaces';
import ProductComponent from '../product/product';
import CloseButton from '../closeButton/closeButton';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const SettingsModal: NextPage<Props> = ({ open, setOpen }) => {
  const { organizationData } = useGlobalState();
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [loadingSubscriptionDetails, setLoadingSubscriptionDetails] = useState<boolean>(false);
  const [activePeriod, setActivePeriod] = useState<string>('year');
  const [controller] = useState<AbortController>(new AbortController());
  const { width } = useWindowSize();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (!availableProducts.length && open) {
      (async function iife () {
        try {
          setLoadingProducts(true);
          const response: AxiosResponse<Product[]> = await axios.get(
            '/api/products',
            { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
          );
          setAvailableProducts(response.data);
        } catch (error) {
          if (!axios.isCancel(error)) {
            setAvailableProducts([]);
          }
        } finally {
          setLoadingProducts(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCheckSubscription = async (product?: Product, priceId?: string) => {
    if (organizationData) {
      try {
        setLoadingSubscriptionDetails(true);
        const response: AxiosResponse<{ url: string }> = await axios.post(
          `/api/clients/${organizationData.id}/subscriptions`,
          {},
          { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
        );
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      } catch (error) {
        if (!axios.isCancel(error)) {

        }
      } finally {
        setLoadingSubscriptionDetails(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='settings-title'
      aria-describedby='settings-description'
      fullScreen={width <= 768 ? true : false}
      fullWidth
      classes={{
        paper: 'u-br-12-px'
      }}
      maxWidth='lg'
    >
      <Box className='u-p-24 u-relative'>
        <CloseButton handleCloseModal={() => setOpen(false)} />
        <Typography className='fs-title-big'>Plan & Billing</Typography>
        <Box>
          {loadingProducts ? (
            <Box className='u-flex u-flex-justify-center' sx={{ gap: '80px' }}>
              {Array.from(Array(1).keys()).map((item: number) => (
                <Skeleton key={item} height={400} className='u-flex-33' sx={{ maxWidth: '300px' }} />
              ))}
            </Box>
          ) : (
            availableProducts.length ? (
              <>
                <Box className='u-flex u-flex-justify-center'>
                  <Box className='u-flex u-flex-justify-center u-p-4 u-br-6-px' sx={{ background: '#f3f3f3' }}>
                    <Box
                      onClick={() => setActivePeriod('month')}
                      className='u-py-6 u-px-16 u-cursor-pointer u-br-6-px'
                      sx={{
                        background: activePeriod === 'month' ? '#232f3e' : '#f3f3f3',
                        color: activePeriod === 'month' ? '#fff' : '#232f3e',
                      }}
                    >
                      Monthly
                    </Box>
                    <Box
                      onClick={() => setActivePeriod('year')}
                      className='u-py-6 u-px-16 u-cursor-pointer u-br-6-px'
                      sx={{
                        background: activePeriod === 'year' ? '#232f3e' : '#f3f3f3',
                        color: activePeriod === 'year' ? '#fff' : '#232f3e',
                      }}
                    >
                      Yearly
                    </Box>
                  </Box>
                </Box>
                <Box className='u-flex u-flex-justify-center u-p-24' sx={{ gap: '80px' }}>
                  {availableProducts.map((product: Product) => (
                    <ProductComponent
                      key={product.id}
                      product={product}
                      handleSelectProduct={handleCheckSubscription}
                      loading={loadingSubscriptionDetails}
                      onboardingScreen={false}
                      activePeriod={activePeriod}
                    />
                  ))}
                </Box>
              </>
            ) : null
          )}
        </Box>
        <Box>
          <Typography
            className='fs-title-regular u-text-decoration-underline u-cursor-pointer u-text-align-center'
            onClick={() => handleCheckSubscription()}
          >
            Manage your plan or billing
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SettingsModal;
