import type { NextPage } from 'next';
import { useEffect, useState, useRef } from 'react';
import { Drawer, Box, Typography, Stack, CircularProgress, IconButton } from '@mui/material';
import CloseButton from '../closeButton/closeButton';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { parseISO, format } from 'date-fns';
import axios, { AxiosResponse } from 'axios';
import { useGlobalState } from '../../context/globalState';
import useWindowSize from '../../utils/useWindowSize';
import { Sort } from '../../types/Cube.interfaces';
import { ExportHistory, ExportHistoryAndCount } from '../../types/Common.interfaces';
import TableCheckboxFilter from '../tableCheckboxFilter/tableCheckboxFilter';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ExportHistoryDrawer: NextPage<Props> = ({ open, setOpen }) => {
  const { organizationData, loadingOrganizationData, activeDataset } = useGlobalState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortData, setSortData] = useState<Sort>({ column: 'exportedAt', value: 'desc' });
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loadingExports, setLoadingExports] = useState<boolean>(false);
  const [exportData, setExportData] = useState<ExportHistory[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [controller] = useState<AbortController>(new AbortController());
  const { height } = useWindowSize();
  const buttonRef = useRef<any>();
  const iconRef = useRef<any>();

  useEffect(() => {
    if (open && organizationData && activeDataset) {
      loadExportData(sortData.column, sortData.value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, activeDataset, selectedOptions, open]);

  const loadExportData = async (orderBy: string, direction: string) => {
    if (organizationData && activeDataset) {
      try {
        setLoadingExports(true);
        const countParam = 100;
        const pageParam = 1;
        const filterParams = selectedOptions.length > 0 ? `&filters=${selectedOptions.join(',')}` : '';
        const queryParams = `?count=${countParam}&page=${pageParam}${filterParams}&orderBy=${orderBy}&direction=${direction}`;
        const response: AxiosResponse<ExportHistoryAndCount> = await axios.get(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/export-history${queryParams}`,
          { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
        );
        setExportData(response.data.exports);
        setRowCount(response.data.totalCount);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setExportData([]);
          setRowCount(0);
        }
      }
      finally {
        setLoadingExports(false);
      }
    }
  };

  const handleSortChange = async (model: GridSortModel) => {
    if (model.length && model[0].sort) {
      setSortData({ column: model[0].field, value: model[0].sort });
      loadExportData(model[0].field, model[0].sort);
    } else {
      setSortData({ column: 'exportedAt', value: 'desc' });
      loadExportData('exportedAt', 'desc');
    }
  };

  const handleFilterChange = (value: string[]) => {
    setSelectedOptions(value);
  };

  const handleToggleFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseFilter = (event:  MouseEvent | TouchEvent | React.MouseEvent<HTMLElement>) => {
    if (event.target !== buttonRef.current && event.target !== iconRef.current) {
      setAnchorEl(null);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'author',
      headerName: 'By',
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.user.firstName && params.row.user.lastName ?
            `${params.row.user.firstName} ${params.row.user.lastName}` : `${params.row.email || ''}`}
        </>
      ),
      headerClassName: 'table-header'
    },
    {
      field: 'exportedAt',
      headerName: 'Date',
      width: 140,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <>
              {format(parseISO(params.value), 'MM/dd/yyyy')}
            </>
          );
        } else {
          return <></>;
        }
      },
      headerClassName: 'table-header'
    }
  ];

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={() => setOpen(false)}
      elevation={4}
      PaperProps={{
        sx: { borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }
      }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: 'backdrop-bg'
          }
        }
      }}
    >
      <Box className='u-py-32 u-px-20 u-relative u-h-100 custom-insights-drawer-wrapper'>
        <Typography variant='h4' className='u-pt-12 u-text-align-left'>Export History</Typography>
        <CloseButton handleCloseModal={() => setOpen(false)} />
        <Box className='u-py-32 u-w-100 u-flex u-flex-direction-column' sx={{ height: height - 80, minHeight: '600px' }}>
          <Box className='u-flex u-flex-space-between u-flex-align-center u-px-8 u-pt-8 u-pb-4'>
            <Typography className='table-top-content fs-title-medium'>Export History</Typography>
            <Box>
              <IconButton
                ref={buttonRef}
                onClick={(event: React.MouseEvent<HTMLElement>) => handleToggleFilter(event)}
              >
                <TuneOutlinedIcon
                  ref={iconRef}
                  className='table-top-content-icon'
                />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <DataGridPro
              page={0}
              pageSize={50}
              paginationMode='server'
              columns={columns}
              rows={exportData}
              rowCount={rowCount}
              getRowId={(row: GridRowModel) => row.id}
              loading={loadingOrganizationData || loadingExports}
              sx={{
                borderRadius: '0',
                backgroundColor: '#fff',
              }}
              headerHeight={48}
              rowHeight={48}
              className='export-table'
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              hideFooter
              hideFooterPagination
              pagination={false}
              sortingMode='server'
              sortingOrder={['asc', 'desc']}
              hideFooterSelectedRowCount
              onSortModelChange={(model: GridSortModel) => handleSortChange(model)}
              components={{
                NoRowsOverlay: () => (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    No exports to display!
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    No exports to display!
                  </Stack>
                ),
                LoadingOverlay: () => (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    <CircularProgress className='circular-progress' size={50} />
                  </Stack>
                )
              }}
            />
          </Box>
        </Box>
      </Box>
      <TableCheckboxFilter
        anchorEl={anchorEl}
        handleClose={handleCloseFilter}
        handleFilterChange={handleFilterChange}
        title='Exported By'
        selectedOptions={selectedOptions}
        filterEntity='exports'
      />
    </Drawer>
  );
};

export default ExportHistoryDrawer;