import { NextPage } from 'next';
import { useState } from 'react';
import { Box, Typography, Button, CircularProgress, Skeleton } from '@mui/material';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import { differenceInCalendarDays } from 'date-fns';
import { useGlobalState } from '../../context/globalState';
import axios, { AxiosResponse } from 'axios';

const MonthlySubscriptionHeader: NextPage = () => {
  const { subscription, organizationData, exportConfigData, loadingExportConfig } = useGlobalState();
  const [loadingSubscriptionDetails, setLoadingSubscriptionDetails] = useState<boolean>(false);
  const [controller] = useState<AbortController>(new AbortController());

  const daysUntilTrialEnds = (): string => {
    if (subscription && subscription.current_period_end) {
      const daysDifference = differenceInCalendarDays(new Date(subscription.current_period_end * 1000), new Date(Date.now()));
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'}`;
    } else {
      return '0 days';
    }
  };

  const handleCheckSubscription = async () => {
    if (organizationData) {
      try {
        setLoadingSubscriptionDetails(true);
        const response: AxiosResponse<{ url: string }> = await axios.post(
          `/api/clients/${organizationData.id}/subscriptions`,
          {},
          { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
        );
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      } catch (error) {
        if (!axios.isCancel(error)) {

        }
      } finally {
        setLoadingSubscriptionDetails(false);
      }
    }
  };

  return (
    <Box
      className='u-py-8 u-px-40 u-flex u-flex-justify-end u-flex-align-center free-trial-wrapper'
    >
      <Box>
        <Typography className='u-flex u-flex-align-center fs-title-regular'>
          Remaining credits:
          {' '}
          {loadingExportConfig ? <Skeleton variant='rectangular' width={80} sx={{ display: 'inline-block', ml: 1 }} /> : (
            subscription ? subscription.status === 'trialing' ? (
              exportConfigData ? exportConfigData.availableCredits : ''
            ) : 'Unlimited downloads' : ''
          )}
        </Typography>
      </Box>
      {subscription && subscription.status === 'trialing' ? (
        <Box className='u-flex u-pl-40'>
          <Typography sx={{ mr: 3 }} className='u-flex u-flex-align-center fs-title-regular'>
            <HourglassTopRoundedIcon sx={{ mr: 2 }} fontSize='small' />
            {daysUntilTrialEnds()} left
          </Typography>
          <Button
            className='contained-button header-button u-text-transform-none'
            variant='contained'
            onClick={() => handleCheckSubscription()}
            disabled={loadingSubscriptionDetails}
            size='small'
          >
            {loadingSubscriptionDetails ? <CircularProgress sx={{ color: '#fff', marginRight: '5px' }} size={12} /> : null}
            Upgrade Now
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default MonthlySubscriptionHeader;