import type { NextPage } from 'next';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import Loading from '../loading/loading';
import SelectOrganization from '../selectOrganization/selectOrganization';
import SideMenu from '../sideMenu/sideMenu';
import Toast from '../toast/toast';
import CancelExportModal from '../cancelExportModal/cancelExportModal';
import FileExport from '../fileExport/fileExport';
import TamDrawer from '../tamDrawer/tamDrawer';
import PersonaDefinitionDrawer from '../personaDefinitionDrawer/personaDefinitionDrawer';
import ExportHistoryDrawer from '../exportHistoryDrawer/exportHistoryDrawer';
import PeopleDrawer from '../peopleDrawer/peopleDrawer';
import MonthlySubscriptionHeader from '../monthlySubscriptionHeader/monthlySubscriptionHeader';
import CheckingSubscriptionsModal from '../checkingSubscriptionsModal/checkingSubscriptionsModal';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { useExportState } from '../../context/exportState';
import useWindowSize from '../../utils/useWindowSize';
import { useAuth } from '@clerk/nextjs';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import CustomInsightsDrawer from '../customInsightsDrawer/customInsightsDrawer';
import SettingsModal from '../settingsModal/settingsModal';

interface Props {
  title: string;
  padding?: boolean;
  children: React.ReactNode;
}

const Layout: NextPage<Props> = ({ title, children, padding }) => {
  const { isLoaded, isSignedIn } = useAuth();
  const { loading, loadingOrganizationData, checkingSubscription } = useGlobalState();
  const {
    duration, menuWidth, isExportOpen, setIsExportOpen, openTamDrawer, setOpenTamDrawer, openPersonaDefinitionDrawer, setOpenPersonaDefinitionDrawer,
    openExportHistoryDrawer, setOpenExportHistoryDrawer, openPeopleDrawer, setOpenPeopleDrawer, openCustomInsightsDrawer, setOpenCustomInsightsDrawer,
    openSettingsModal, setOpenSettingsModal, openOnboardingModal
  } = useUiState();
  const { cancelExport } = useExportState();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('RevenueBase');
  const { width } = useWindowSize();

  useEffect(() => {
    if (title) {
      setPageTitle(`RevenueBase | ${title}`);
    }
  }, [title]);

  const contentStyle = {
    transition: `width ${duration}ms`,
  };

  const contentTransitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
    entered: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
    exiting: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
    exited: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
  };

  return (
    <div className='app'>
      <Head>
        <title>{pageTitle}</title>
        <meta name='description' content='RevenueBase Portal' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <main style={!isSignedIn ? { background: '#2e2e2e', minHeight: '100vh' } : {}}>
        {!isLoaded || loading || loadingOrganizationData ? (
          <Loading transparentBackground={false} />
        ) : (
          <>
            {!isSignedIn ? <></> : (
              openOnboardingModal ? (
                <SelectOrganization />
              ) : (
                <>
                  <SideMenu />
                  <Transition timeout={duration}>
                    {(state: TransitionStatus) => (
                      <div className='main-container' style={{ ...contentStyle, ...contentTransitionStyles[state] }}>
                        <MonthlySubscriptionHeader />
                        {children}
                        {isExportOpen ? <FileExport setOpenCancelModal={setOpenCancelModal} layoutPadding={!!padding} /> : null}
                        <CheckingSubscriptionsModal />
                      </div>
                    )}
                  </Transition>
                </>
              )
            )}
            <CancelExportModal
              open={openCancelModal}
              setOpen={setOpenCancelModal}
              handleAccept={() => {
                setIsExportOpen(false);
                cancelExport();
              }}
            />
            <TamDrawer
              open={openTamDrawer}
              setOpen={setOpenTamDrawer}
            />
            <PersonaDefinitionDrawer
              open={openPersonaDefinitionDrawer}
              setOpen={setOpenPersonaDefinitionDrawer}
            />
            <ExportHistoryDrawer
              open={openExportHistoryDrawer}
              setOpen={setOpenExportHistoryDrawer}
            />
            <PeopleDrawer
              open={openPeopleDrawer}
              setOpen={setOpenPeopleDrawer}
            />
            <CustomInsightsDrawer
              open={openCustomInsightsDrawer}
              setOpen={setOpenCustomInsightsDrawer}
            />
            <SettingsModal
              open={openSettingsModal}
              setOpen={setOpenSettingsModal}
            />
            {checkingSubscription ? <Loading transparentBackground={false} /> : null}
            <Toast />
          </>
        )}
      </main>
    </div>
  );
};

export default Layout;
