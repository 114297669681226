import type { NextPage } from 'next';
import { useState, useEffect } from 'react';
import { Box, Typography, Card, Button, CircularProgress } from '@mui/material';
import { Product } from '../../types/Stripe.interfaces';
import Stripe from 'stripe';
import { useGlobalState } from '../../context/globalState';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  product: Product;
  handleSelectProduct: (product: Product, priceId: string) => void;
  loading?: boolean;
  loadingProductId?: string;
  onboardingScreen: boolean;
  activePeriod: string;
}

const Product: NextPage<Props> = ({ product, handleSelectProduct, loading, loadingProductId, onboardingScreen, activePeriod }) => {
  const { subscription } = useGlobalState();
  const [periodPriceId, setPeriodPriceId] = useState<string>('');

  useEffect(() => {
    if (product && activePeriod) {
      const selectedPrice = product.prices.find((price: Stripe.Price) => price.recurring?.interval === activePeriod);
      setPeriodPriceId(selectedPrice ? selectedPrice.id : '');
    }
  }, [product, activePeriod]);

  const formatNumber = (value: string): string => {
    return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    periodPriceId ? (
      <Card
        key={product.id}
        className='u-p-16 u-flex-33 u-relative u-flex u-flex-space-between u-flex-direction-column'
        sx={{ borderRadius: '8px', overflowY: 'scroll', maxWidth: '300px' }}
      >
        <Box>
          <Box className='u-flex u-flex-space-between u-flex-align-center u-pb-16'>
            <Typography className='u-text-align-left fs-title-big u-fw-400'>{product.name}</Typography>
            {subscription && subscription.product_id === product.id && subscription.price_id === periodPriceId ? (
              <Typography className='u-flex u-flex-align-center'>
                Current plan
                <CheckIcon color='success' sx={{ marginLeft: '6px' }} />
              </Typography>
            ) : null}
          </Box>
          {product.prices.length ? (
            product.prices
              .filter((price: Stripe.Price) => price.recurring && price.recurring.interval === activePeriod)
              .map((price: Stripe.Price) => (
                <Box className='u-pb-16' key={price.id}>
                  <Typography className='u-text-align-left fs-title-large u-fw-700' sx={{ lineHeight: '1.1' }}>
                    {price.unit_amount ? `$${formatNumber(String(price.unit_amount / 100))}` : ''}
                  </Typography>
                  {price.recurring && price.recurring.interval ? (
                    <Typography className='u-text-align-left' sx={{ lineHeight: '1' }}>
                      per {price.recurring.interval}
                    </Typography>
                  ) : null}
                </Box>
              ))
          ) : null}
          {onboardingScreen ? (
            <Box className='u-pb-16'>
              <Button
                variant='contained'
                className='contained-crm-button u-text-transform-none'
                onClick={() => periodPriceId && handleSelectProduct(product, periodPriceId)}
                sx={{ mb: 2 }}
                disabled={loading}
                classes={{
                  disabled: 'disabled-button'
                }}
              >
                {loading && loadingProductId === product.id ? (
                  <CircularProgress sx={{ color: '#fff', marginRight: '5px' }} size={20} />
                ) : null}
                Select
              </Button>
            </Box>
          ) : null}
        </Box>
        <Box className='u-w-100 u-py-12'>
          <Box className='features-wrapper'>
            {product.features.map((feature: Stripe.Product.Feature) => (
              <Box key={feature.name} className='u-flex u-flex-align-start'>
                <Box sx={{ margin: 0, display: 'list-item', listStylePosition: 'inside' }}></Box>
                <Typography
                  className='u-pb-4 fs-body-regular'
                  sx={{ margin: 0 }}
                >
                  {feature.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Card>
    ) : null
  );
};

export default Product;
