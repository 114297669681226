import type { NextPage } from 'next';
import { Dialog, Box, Button, Typography, CircularProgress } from '@mui/material';
import CloseButton from '../closeButton/closeButton';
import { useUiState } from '../../context/uiState';
import { useGlobalState } from '../../context/globalState';

const CheckingSubscriptionsModal: NextPage = () => {
  const { openChangeOrgModal, setOpenChangeOrgModal } = useUiState();
  const {
    changeOrganization, checkingSubscription, setCheckedSubscriptionCanceled, organizationToChange, checkedSubscriptionCanceled
  } = useGlobalState();

  const handleCloseModal = () => {
    setOpenChangeOrgModal(false);
    setCheckedSubscriptionCanceled(false);
  };

  return (
    <Dialog
      open={openChangeOrgModal}
      onClose={() => handleCloseModal()}
      aria-labelledby='settings-title'
      aria-describedby='settings-description'
      fullScreen={false}
      fullWidth
      classes={{
        paper: 'dialog-root u-br-12-px'
      }}
      maxWidth='sm'
    >
      <Box className='u-py-24 u-px-48 u-w-100'>
        <CloseButton handleCloseModal={() => handleCloseModal()} />
        <Typography className='fs-title-large u-text-align-center u-pb-16'>
          {checkingSubscription ? <CircularProgress className='u-mr-8' size={20} /> : null}
          Subscription canceled
        </Typography>
        {checkedSubscriptionCanceled ? (
          <>
            <Typography>
              Organization that you want to change has canceled its subscription. Do you wish to proceed anyway?
            </Typography>
            <Box className='u-flex u-flex-justify-end u-pt-24'>
              <Button
                onClick={() => handleCloseModal()}
                className='details-button u-mr-12'
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (organizationToChange) {
                    changeOrganization(organizationToChange);
                  }
                }}
                className='contained-button'
                variant='contained'
              >
                Change Organization
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </Dialog>
  );
};

export default CheckingSubscriptionsModal;
